exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-collection-author-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.author.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-author-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-collection-category-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.category.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-category-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-post-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/post.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-post-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-posts-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/posts.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-posts-js" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-authors-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/authors.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-authors-jsx" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-contact-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/contact.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-contact-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-gizlilik-sozlesmesi-jsx": () => import("./../../../src/pages/gizlilik-sozlesmesi.jsx" /* webpackChunkName: "component---src-pages-gizlilik-sozlesmesi-jsx" */),
  "component---src-pages-hakkimizda-jsx": () => import("./../../../src/pages/hakkimizda.jsx" /* webpackChunkName: "component---src-pages-hakkimizda-jsx" */),
  "component---src-pages-iletisim-jsx": () => import("./../../../src/pages/iletisim.jsx" /* webpackChunkName: "component---src-pages-iletisim-jsx" */),
  "component---src-pages-kisisel-verileri-koruma-jsx": () => import("./../../../src/pages/kisisel-verileri-koruma.jsx" /* webpackChunkName: "component---src-pages-kisisel-verileri-koruma-jsx" */),
  "component---src-pages-kullanim-sozlesmesi-jsx": () => import("./../../../src/pages/kullanim-sozlesmesi.jsx" /* webpackChunkName: "component---src-pages-kullanim-sozlesmesi-jsx" */),
  "component---src-pages-yazarlar-jsx": () => import("./../../../src/pages/yazarlar.jsx" /* webpackChunkName: "component---src-pages-yazarlar-jsx" */)
}

